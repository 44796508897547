<template >
  
  <v-row class="justify-center test">
    <v-col  cols="12" md="6" xs="12" lg="4">
      <v-card shaped >
        <v-card-title style="display:block" class="display-1 text-uppercase subtitle-1 text-center">
            Cambiar contraseña
        </v-card-title>
            <!-- <h1>Login</h1> -->
        <v-card-text >
            <div class="error" v-if="!$v.repeatPassword.sameAsPassword">Las contraseñas deben ser identicas</div>
          <form @submit.prevent="updatePassword({password, user})" autocomplete="off">
            <v-text-field
              class="form-group" :class="{ 'form-group--error': $v.password.$error }"
              v-model="password"
              :rules="passRules"
              label="Nueva contraseña" 
              type="password"
              
              required
            >  </v-text-field>
           
            <v-text-field
              class="form-group" :class="{ 'form-group--error': $v.repeatPassword.$error }"
              v-model="repeatPassword"
              label= "Confirmar contraseña" 
              
              type="password"
            >  </v-text-field>
             
            <div class="text-center">
              <v-btn block color="primary" dark type="submit">Guardar</v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  
  
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from "vuex"
import {required,sameAs, minLength} from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
export default {
  name: "NuevaContraseña",
  data() {
    return {
      user: this.$route.params.user,
      password: "",
      repeatPassword: "",
      passRules:[
        v => !!v || 'Contraseña requerida'
      ],
      passrules2:[
       
      ]
    };
  },
  validations:{
      password: {
       required,
      minLength: minLength(5)
      },
      repeatPassword:{
        sameAsPassword: sameAs('password')
      }

    },
  methods: {
    ...mapActions(["updatePassword"])
  },
  computed: {
    ...mapState(["error"])
  }
  
};
</script>

<style>
    .test {
        padding-top: 10em;
    }
    .error {
      background: black !important;
      color: white;
      margin-bottom: 5px;
      border-radius: 2px;
    }
</style>